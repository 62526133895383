<template>
  <v-card flat>
    <v-card-title>
      <menu-title class="ml-4" title="Announcements"> </menu-title>
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="tab">
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
          v-for="tab in tabs"
          :key="tab.value"
          color="primary"
          active-class="selected_tab_class"
          :to="tab.route"
        >
          {{ tab.value }}
        </v-tab>
      </v-tabs>
      <router-view></router-view>
    </v-card-text>
  </v-card>
</template>



<script>
import menuTitle from "../../components/helpers/menu_title.vue";
import viewAnnouncement from "../../components/admin/announcements/view/announcementstab.vue";

export default {
  name: "AnnouncementEditor",
  components: {
    menuTitle,
    viewAnnouncement,
  },
  data: () => ({
    tab: null,
    tabs: [
      {
        key: "writeannouncement",
        value: "Write Announcement",
        route: { path: "/admin/announcements/write" },
      },
      {
        key: "viewannouncements",
        value: "View Announcements",
        route: { path: "/admin/announcements/view" },
      },
    ],
  }),
};
</script>

<style scoped>
</style>